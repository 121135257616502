<template>
  <div id="containerbar" data-app>
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-8 col-lg-8">
            <h4 class="page-title">{{ $t(title) }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard"> {{ $t("warehouse_cyclical.home") }} </a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }} </a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("warehouse_cyclical.warehouse_cyclical") }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <template>
              <div class="card">
                <v-card-title>
                  <div class="row">
                    <div class="col-md-10">
                      <h5 class="card-title mb-0">
                        <i class="feather icon-refresh-cw mr-2"></i> 
                        {{ $t("warehouse_cyclical.warehouse_cyclical") }}
                      </h5>
                    </div>
                  </div>
                </v-card-title>
                <div class="p-2">
                  <v-data-table
                    dense
                    :headers="mainStockHeader"
                    :items="mainStockList"
                    height="auto"
                    fixed-header
                    :footer-props="{
                      'items-per-page-text': $t('table.item_table'),
                      'items-per-page-all-text': $t('table.all'),
                      'items-per-page-options': [15, 30, 50, 100],
                    }"
                    :loading-text="$t('table.loading')"
                    :no-data-text="$t('table.no_results_available')"
                    :no-results-text="$t('table.no_results_search')"
                    :search="searchTableMain"
                    :item-class="itemRowBackground"
                  >
                    <template v-slot:top>
                      <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6 selectSize">
                          <v-text-field
                            v-model="searchTableMain"
                            :label="$t('table.search')"
                            class="pl-1"
                          ></v-text-field>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <div
                        v-if="
                          withPermissionName(permissions.WAR_INV_CYC_ADD_COM_01)
                        "
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="hand pr-3"
                              @click="viewModalObservations(item)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <i :class="iconModal(item) + ' pt-5'"></i>
                            </span>
                          </template>
                          <span>
                            {{ $t("warehouse_cyclical.observations") }}
                          </span>
                        </v-tooltip>
                      </div>
                      <div v-else>-</div>
                    </template>
                  </v-data-table>
                  <ButtonPrintComponent />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogCyclical" persistent max-width="700">
      <v-card>
        <v-card-title class="headline">
          <h3>{{
            $t("warehouse_cyclical.observations")
          }}</h3>
        </v-card-title>
        <v-container class="p-4">
          <AlertMessageComponent ref="alertCyclical" />
          <p>
            <i class="ti-pin-alt mr-2 text-fmedical-blue"></i>
            <strong>
              <span> {{ $t("warehouse_cyclical.id_stock") }} </span>
            </strong>
            {{ itemRow.idStock }}
          </p>
          <p>
            <i class="ti-package mr-2 text-fmedical-blue"></i>
            <strong>
              <span> {{ $t("warehouse_cyclical.causes") }}: </span>
            </strong>
            {{ causes }}
          </p>
          <p class="pb-3">
            <i class="ti-align-center mr-2 text-fmedical-blue"></i>
            <strong>
              <span> {{ $t("warehouse_cyclical.description") }} </span>
            </strong>
            {{ completeName }}
          </p>
          <div class="row">
            <div class="col-md-3">
              <p>
                <i class="ti-layout-grid2 mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("warehouse_cyclical.lot") }} </span>
                </strong>
                {{ itemRow.lote }}
              </p>
            </div>
            <div class="col-md-5">
              <p>
                <i class="ti-calendar mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("warehouse_cyclical.expiration") }}</span>
                </strong>
                {{ itemRow.expirationDate }}
              </p>
            </div>
            <!--<div class="col-md-4">
              <p>
                <i class="ti-layers mr-2 text-fmedical-blue"></i>
                <strong> <span> {{$t('warehouse_cyclical.quantity_in_stock')}}</span> </strong>
                {{ itemRow.quantity }}
              </p>
            </div>-->
          </div>
          <hr />
          <validation-observer ref="observer1" v-slot="{ invalid }">
            <form @submit.prevent="addObservation">
              <div class="row pb-5">
                <div class="col-md-12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="actual_quantity"
                    rules="required|numeric|max:10"
                  >
                    <v-text-field
                      :disabled="sending"
                      v-model="realQuantityAux"
                      :counter="10"
                      :maxlength="10"
                      :error-messages="errors"
                      :label="$t('label.actual_quantity')"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="note"
                    rules="required|max:100|regex:^[0-9A-ZÁÉÍÑÓÚÜ]+(?:[\s][0-9A-ZÁÉÍÑÓÚÜ]+)*$"
                  >
                    <v-textarea
                      outlined
                      v-model="observationAux"
                      :counter="100"
                      :maxlength="100"
                      :error-messages="errors"
                      :label="$t('label.observation')"
                      required
                      oninput="this.value = this.value.toUpperCase()"
                    ></v-textarea>
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <button
                    class="btn btn-outline-success mr-4 mt-4"
                    type="submit"
                    :disabled="sending || invalid"
                  >
                    <i class="ti-comment-alt mr-2"></i
                    >{{ $t("global.save_remark") }}
                  </button>
                  <button
                    class="btn btn-outline-danger mt-4"
                    :disabled="sending"
                    @click="cleanModalObservations()"
                  >
                    <i class="feather icon-x mr-1"></i> {{ $t("global.cancel") }}
                  </button>
                </div>
              </div>
            </form>
          </validation-observer>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import axios from "axios";
import SelectBranchOffice from "@/common/select/SelectBranchOffice.vue";
import AlertMessageComponent from "@/common/message/AlertMessageComponent.vue";
import { ServicePath, UtilFront, PermissionConstants } from "@/core";
import { mapState, mapMutations } from "vuex";
import { StockRequest } from "@/core/request";
import { STOCK_OBSERVATION } from "@/core/DataTableHeadersLocale";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "warehouse_cyclical.title",

      /* General */
      idBranchOffice: 0,
      idUser: 0,
      loading: null,

      /* Tabla */
      mainStockList: [],
      mainStockHeader: STOCK_OBSERVATION(),
      searchTableMain: "",

      /* Modal */
      dialogCyclical: false,
      itemRow: {},
      modelObservations: {},

      /* Cargas */
      showSave: false,
      sending: false,

      permissions: PermissionConstants,
      observationAux: "",
      realQuantityAux: "",
      completeName: "",
      causes: "",
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectBranchOffice,
    AlertMessageComponent,
    ButtonPrintComponent,
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    /* Modal */
    viewModalObservations(item) {
      //Mustro la informacion
      this.dialogCyclical = true;
      //Guardo todo el objeto
      this.causes = item.products.productKey;
      this.itemRow = item;
      this.completeName = item.products.completeDescription;
      this.observationAux = item.observation;
      this.realQuantityAux = item.realQuantity;
    },
    cleanModalObservations() {
      //Oculto el modal
      this.dialogCyclical = false;
      //Limpio el objeto
      this.modelObservations = {};
      //Limpio el formulario
      this.$refs.observer1.reset();

      this.observationAux = "";
      this.realQuantityAux = null;
    },
    updateItemOrigin(data) {
      this.idBranchOffice = data;
      this.listAll();
    },
    listAll() {
      this.mainStockList = [];
      if (this.idBranchOffice != null) {
        axios
          .post(ServicePath.GET_ALL_STOCK_WITH_OBSERVATION_BY_BRANCH, {
            idUser: this.idUser,
            idBranchOffice: this.idBranchOffice,
          })
          .then((response) => {
            for (var [index, value] of Object.entries(
              response.data.data.data
            )) {
              let mainStockitem = {
                fiscalFund: value.fiscalFund.key,
                idProduct: value.idProduct,
                idStock: value.idStock,
                locationKey: value.locationkey,
                lote: value.lot,
                products: value.products,
                quantity: value.quantity,
                status: value.status,
                expirationDate: value.expirationDate,
                idProductRemmision: value.idProductRemmision,
                realQuantity: value.stockObservation.realQuantity,
                observation: value.stockObservation.observation,
              };
              this.mainStockList.push(mainStockitem);
            }
          });
      }
    },
    addObservation() {
      //Mustro pantalla de carga
      this.loading = UtilFront.getLoadding();

      this.sending = true;

      //Guardo el id de stock
      this.modelObservations.stockId = this.itemRow.idStock;
      this.modelObservations.realQuantity = this.realQuantityAux;
      this.modelObservations.observation = this.observationAux;

      //Usuario
      this.modelObservations.userBranchOffice = {
        idUser: this.idUser,
        idBranchOffice: this.idBranchOffice,
      };

      StockRequest.stockObservation(this.modelObservations)
        .then((response) => {
          let { data } = response.data;
          let { message, success } = data;

          if (success) {
            this.$refs.alertCyclical.reloadComponent(
              "success",
              "Observación guardada."
            );
            //this.listAll();
            this.itemRow.save = true;
            this.itemRow.observation = this.observationAux;
            this.itemRow.realQuantity = this.realQuantityAux;
          } else {
            this.$refs.alertCyclical.reloadComponent("error", message);
          }
        })
        .catch((error) => {
          this.$refs.alertCyclical.reloadComponent("internal", "");
        })
        .finally((response) => {
          this.sending = false;
          //Cierro la pantalla de carga
          this.loading.hide();
        });
    },
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    //Modifico el diseño del icono dependiendo de si tiene informacion o no
    iconModal(item) {
      return (item.observation == null || item.observation == "") &&
        (item.realQuantity == null || item.realQuantity == "")
        ? "ti-comment"
        : "ti-comment-alt";
    },
    //Modifico el color de la fila
    itemRowBackground: function (item, index) {
      return (item.observation == null || item.observation == "") &&
        (item.realQuantity == null || item.realQuantity == "")
        ? ""
        : "backgroud-green";
    },
  },
  created() {
    let infoUser = UtilFront.getDataUser();
    this.idUser = infoUser.idUser;
    this.updateItemOrigin(this.branchOfficeState.idBranchoffice);
    this.updateAction(1);
  },
  watch: {
    branchOfficeState() {
      this.updateItemOrigin(this.branchOfficeState.idBranchoffice);
    },
  },
};
</script>
