<template>
  <div>
    <v-autocomplete
      :disabled="disabledView"
      v-if="originList.length > 1"
      v-model="originId"
      @change="updateItem"
      :items="originList"
      label="Sucursal de Origen"
      data-vv-name="select"
    />
  </div>
  <!--<v-select :disabled="disabledView" v-if="originList.length > 1" v-model="originId" @change="updateItem" :items="originList" label="Sucursal" data-vv-name="select" />->-->
</template>

<script>
import { UtilFront } from "@/core";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    disabledView: false,
  },
  computed: {
    ...mapState("auth", ["branchOffice", "listBranchOffice"]),
  },
  data() {
    return {
      originId: "",
      originList: [],
    };
  },
  methods: {
    updateItem(data) {
      this.$emit("updateItemOrigin", data.idBranchoffice);
      this.updateBrand(data);
    },
    getCompleteItem(data) {
      this.$emit("getCompleteItem", data);
    },
    ...mapMutations("auth", {
      updateBrand: "UPDATE_VALUE",
    }),
  },
  created() {
    let infoUser = UtilFront.getDataUser();
    this.originList = infoUser.branchOfficesSimple;
    // Verifica que alguna sucursal global este seleccionada
    if (this.branchOffice == null) {
      this.originId = this.originList[0].value;
      this.updateBrand(this.originList[0].value);
      this.$emit("updateItemOrigin", this.originList[0].value.idBranchoffice);
    } else {
      this.originId = this.branchOffice;
      this.$emit("updateItemOrigin", this.branchOffice.idBranchoffice);
    }
  },
  watch: {
    // En caso de que cambie la sucursal actualiza la tabla
    branchOffice() {
      this.updateBrand(this.branchOffice);
      this.originId = this.branchOffice;
    },
  },
};
</script>
